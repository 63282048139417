import styled from "@emotion/styled";

import { HEADER_HEIGHT } from "@/constants/style/layout";
import { GnbMenuItem } from "@/layout/header/gnb-menu/gnb-menu-item";
import { useCourseControllerGetSectionsGlobal } from "@shared/generated/api/fn/kac/course/course";
import { useRouter } from "next/router";

export const GnbMenu = () => {
  const router = useRouter();
  const lang = (router.locale as "ko" | "en") || "ko";
  const { data: sectionData } = useCourseControllerGetSectionsGlobal(lang, {
    visible: true,
  });

  return (
    <GnbMenuContainer>
      <ul className="main">
        {sectionData?.map((menu, index) => (
          <GnbMenuItem
            key={`menu-row-${index}`}
            main={menu}
            index={index}
          />
        ))}
      </ul>
    </GnbMenuContainer>
  );
};

const GnbMenuContainer = styled.nav`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  ul {
    li {
      a {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  ul.main {
    display: flex;
    align-items: center;
    gap: 32px;
    height: 100%;

    li.active {
      position: relative;
      bottom: 0;
      border-bottom: 2px solid white;
    }
    ${({ theme }) => theme.media.tablet} {
      li.active {
        position: static;
        border-bottom: none;
      }
    }

    li.main {
      cursor: pointer;
      display: flex;
      align-items: center;

      min-height: 16px;
      height: 100%;
      padding-top: 8px;

      &:first-of-type {
        padding-top: 8px;
      }

      a {
        padding-bottom: 8px;
        span.main {
          height: 100%;
          display: flex;
          align-items: center;

          color: ${({ theme }) => theme.colors.text.high.white};
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 14px */
        }
        span.active {
          font-weight: 700;
        }
      }

      &.is-sub {
        &:hover {
          z-index: 1;
          position: relative;

          a.main {
            padding-bottom: 7px;
            border-bottom: 1px solid
              ${({ theme }) => theme.colors.brand.white.default};
          }

          ul.sub {
            z-index: -1;
            position: absolute;
            top: 32px;
            left: 0;

            display: flex;
            width: 104px;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            border-radius: 0 0 8px 8px;
            background: rgba(0, 0, 0, 0.8);

            &.first {
              left: -16px;
            }
          }
        }

        ul.sub {
          display: none;
          li.sub {
            min-height: 19px;
            span.sub {
              color: ${({ theme }) => theme.colors.text.high.white};
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 100%; /* 14px */
              letter-spacing: -0.4px;
            }

            &:hover {
              span.sub {
                color: ${({ theme }) => theme.colors.brand.primary.default};
              }
            }
          }
        }
      }
    }
  }

  ${({ theme }) => theme.media.tablet} {
    overflow-x: scroll;
    ul.main {
      li.main {
        &.is-sub {
          &:hover {
            ul.sub {
              width: 100vw;
              position: fixed;
              top: ${HEADER_HEIGHT.TABLET}px;
              left: 0;

              &.first {
                left: 0;
              }
            }
          }
        }
      }
    }
  }
`;
