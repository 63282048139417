import Link from "next/link";
import styled from "@emotion/styled";

import { Icon } from "@shared/lib/components/common/atom/icon";
import { SectionGlobalResponseDto } from "@shared/generated/api/model";

interface HamburgerMenuItemProps {
  main: SectionGlobalResponseDto;
  index: number;
  closeHamburgerMenu: () => void;
}

export const HamburgerMenuItem = ({
  main,
  index,
  closeHamburgerMenu,
}: HamburgerMenuItemProps) => {
  return (
    <li
      className={"main"}
      key={`main-menu-${index}`}
    >
      <Link
        href={`/class/live/${main.router}`}
        target="_self"
        title={main.name}
        passHref
        onClick={closeHamburgerMenu}
      >
        <span className="main">{main.name}</span>
      </Link>
    </li>
  );
};

const MenuArrowIcon = styled(Icon)`
  width: 16px;
  height: 16px;

  path {
    stroke: ${({ theme }) => theme.colors.icon.active.black};
  }
`;
