import Link from "next/link";
import { SectionGlobalResponseDto } from "@shared/generated/api/model";
import { useRouter } from "next/router";

interface GnbMenuItemProps {
  main: SectionGlobalResponseDto;
  index: number;
}

export const GnbMenuItem = ({ main, index }: GnbMenuItemProps) => {
  const router = useRouter();
  const category = router.query.category;

  return (
    <li
      className={`main ${category === main.router && "active"}`}
      key={`main-menu-${index}`}
    >
      <Link
        href={`/class/live/${main.router}`}
        target="_self"
        title={main.name}
        passHref
      >
        <span className={`main ${category === main.router && "active"}`}>
          {main.name}
        </span>
      </Link>
    </li>
  );
};
